var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.$style['project-content']},[_c('div',{class:_vm.$style['project-rect']},[_c('ul',{class:_vm.$style['project-rect-lists']},_vm._l((_vm.progressBarData),function(item,index){return _c('li',{key:index,class:[
          _vm.$style['project-rect-item'],
          item.status === '已完成'
            ? _vm.$style['start-item']
            : item.status === '进行中'
            ? _vm.$style['start-progressing']
            : '',
        ],attrs:{"item":item}},[(item.status === '已完成')?_c('div',[_c('span'),_c('div',{class:[
              _vm.$style['desc'],
              index % 2 === 0 ? _vm.$style['item-top'] : _vm.$style['item-bottom'],
            ]},[_c('p',{class:_vm.$style['desc-title']},[_c('span',[_vm._v(_vm._s(item.title)+" 已完成")])]),_c('p',{class:_vm.$style['desc-time']},[_vm._v(_vm._s(item.time))])]),_c('div',{class:_vm.$style['start-bar']})]):_vm._e(),(item.status === '进行中')?_c('div',{class:_vm.$style['status-text']},[_c('span',{staticStyle:{"color":"#FFEF60"}},[_vm._v("进行中")]),_c('div',{class:[
              _vm.$style['desc'],
              index % 2 === 0 ? _vm.$style['item-top'] : _vm.$style['item-bottom'],
            ]},[_c('p',{class:_vm.$style['desc-title']},[_c('span',[_vm._v(_vm._s(item.title))])]),_c('p',{class:_vm.$style['desc-time']},[_vm._v(_vm._s(item.time))])]),_c('div',{class:_vm.$style['progess-bar']})]):_vm._e(),(item.status === '已逾期')?_c('div',{class:_vm.$style['status-text']},[_c('span',{staticStyle:{"color":"#FFEF60"}},[_vm._v("已逾期")]),_c('div',{class:[
              _vm.$style['desc'],
              index % 2 === 0 ? _vm.$style['item-top'] : _vm.$style['item-bottom'],
            ]},[_c('p',{class:_vm.$style['desc-title']},[_c('span',[_vm._v(_vm._s(item.title))])]),_c('p',{class:_vm.$style['desc-time']},[_vm._v(_vm._s(item.time))])]),_c('div',{class:_vm.$style['progess-bar']})]):_vm._e(),(item.status === '未开始')?_c('div',{class:_vm.$style['status-text']},[_c('span',{staticStyle:{"color":"#D8E1F3"}},[_vm._v("未开始")]),_c('div',{class:[
              _vm.$style['desc'],
              index % 2 === 0 ? _vm.$style['item-top'] : _vm.$style['item-bottom'],
            ]},[_c('p',{class:_vm.$style['desc-title']},[_c('span',[_vm._v(_vm._s(item.title))])]),_c('p',{class:_vm.$style['desc-time']},[_vm._v(_vm._s(item.time))])])]):_vm._e()])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }