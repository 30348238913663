<template>
  <div class="project-sort-info">
    <h2 class="title">项目信息</h2>
    <p class="subTitle">
      项目一个页面展示6个项目数据，根据从上往下的优先级展示
    </p>

    <div class="draggable-container">
      <draggable
        v-model="dataLists"
        chosen-class="chosen"
        force-fallback="true"
        group="people"
        animation="500"
        @start="onStart"
        @end="onEnd"
        handle=".img-handle"
      >
        <transition-group>
          <div v-for="item in dataLists" :key="item.title" :class="['item']">
            <img
              class="img-handle"
              src="@/assets/images/screen/sort-handle-icon.svg"
              alt=""
            />
            <p>{{ item.title }}</p>
          </div>
        </transition-group>
      </draggable>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import { Component, Vue } from 'vue-property-decorator';
@Component({
  components: {
    draggable,
  },
})
export default class ProjectInfoSortDialog extends Vue {
  dataLists = [
    { title: '项目名称' },
    { title: '项目地址' },
    { title: '建设单位' },
  ];
  // currentIndex = 0;
  created() {}
  mounted() {}
  onStart(evt) {
    //处理拖拽的影子错位的bug
    var e = evt || window.event;
    var chosen = document.querySelector('.sortable-fallback');
    chosen.style.top = e.item.offsetTop + 'px';
    chosen.style.left = e.item.offsetLeft + 'px';
  }
  onEnd() {
    // console.log(this.dataLists);
    alert('onend');
  }
}
</script>
<style lang="less" scoped>
.project-sort-info {
  .title {
    font-size: 0.14rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #9ac0d9;
  }
  .subTitle {
    font-size: 0.12rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #d8e1f3;
  }
  .draggable-container {
    list-style: none;
    padding: 0;
    height: 378px;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 9%,
      rgba(17, 31, 47, 0.5) 43%,
      #071c38 91%
    );
    .item {
      display: flex;
      align-items: center;
      height: 48px;
      padding-left: 0.16rem;
      .img-handle {
        cursor: pointer;
      }
      p {
        margin: 0;
        padding-left: 0.2rem;
      }
      &:hover {
        background: #223142;
      }
    }
  }
}
</style>
