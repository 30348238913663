<template>
  <div class="project-conceptimg-container">
    <a-carousel
      :dots="false"
      :arrows="data.length > 1"
      :autoplay="data.length > 1"
    >
      <div
        slot="prevArrow"
        class="custom-slick-arrow"
        style="left: 10px;zIndex: 1"
      >
        <a-icon type="left-circle" />
      </div>
      <div slot="nextArrow" class="custom-slick-arrow" style="right: 10px">
        <a-icon type="right-circle" />
      </div>
      <div
        class="project-lists-item"
        :key="index"
        v-for="(item, index) in data"
      >
        <x-oss-image
          :key="index"
          basePath="/oss/oss"
          :ossPath="item.value"
          class="carousel-img"
          @click="openImg(imgLists, index)"
        />
      </div>
    </a-carousel>
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { UploadService } from '@triascloud/services';
import { Preview } from '@triascloud/x-components';
@Component({})
export default class ProjectConceptPic extends Vue {
  @Prop({
    type: Array,
    default: () => [],
  })
  data;
  get imgLists() {
    return this.data.map(ele => ele.value);
  }
  created() {}
  mounted() {}
  async openImg(pictures) {
    const basePath = '/oss/oss';
    const customService = new UploadService(basePath);
    const abPath = await customService.getAuth(pictures);
    Preview.createModal(abPath);
  }
}
</script>
<style lang="less" scoped>
.project-conceptimg-container {
  height: 100%;
  .ant-carousel {
    cursor: pointer;
    height: 100%;
  }
  .project-lists-item {
    .carousel-img {
      width: 100%;
      object-fit: cover;
      height: 100%;
    }
  }
}
</style>

<style scoped>
/* For demo */
.ant-carousel >>> .slick-slide {
  text-align: center;
  background: #364d79;
  overflow: hidden;
}

.ant-carousel >>> .custom-slick-arrow {
  width: 25px;
  height: 25px;
  font-size: 25px;
  color: #fff;
  background-color: rgba(31, 45, 61, 0.11);
  opacity: 0.3;
}
.ant-carousel >>> .custom-slick-arrow:before {
  display: none;
}
.ant-carousel >>> .custom-slick-arrow:hover {
  opacity: 0.5;
}

.ant-carousel >>> .slick-slide h3 {
  color: #fff;
}
</style>
