<template>
  <div :class="$style.works">
    <div :class="$style.time">
      <div :class="$style.infoItem">
        <span :class="$style.name">{{ $t('projectView.startTime') }}</span>
        <span :class="$style.text">{{
          progressInfo.startDate
            ? dayjs(progressInfo.startDate).format('YYYY-MM-DD')
            : '--'
        }}</span>
      </div>
      <div :class="$style.infoItem">
        <span :class="$style.name">{{ $t('projectView.plannedTime') }}</span>
        <span :class="$style.text">{{
          progressInfo.endDate
            ? dayjs(progressInfo.endDate).format('YYYY-MM-DD')
            : '--'
        }}</span>
      </div>
      <div :class="$style.infoItem">
        <span :class="$style.name">{{ $t('projectView.constructed') }}</span>
        <span :class="$style.text">{{
          progressInfo.constructionDay >= 0
            ? progressInfo.constructionDay + $t('projectView.day')
            : '--'
        }}</span>
      </div>
    </div>
    <div :class="$style.programme">
      <!-- <a-steps
        v-if="progressInfo.progressList.length > 0 && progressInfo"
        labelPlacement="vertical"
        size="small"
      >
        <a-step
          :status="exchangeStatus(item.status)"
          v-for="(item, index) in progressInfo.progressList"
          :key="index"
        >
          <span
            slot="title"
            :title="item.engineeringStage"
            :class="$style.title"
            >{{ item.engineeringStage }}</span
          >
          <span slot="description" :class="$style.description">{{
            item.progressStartDate > 0
              ? dayjs(item.progressStartDate).format('YYYY-MM-DD')
              : '--'
          }}</span>
        </a-step>
      </a-steps> -->
      <div
        :class="$style.steps"
        v-if="progressInfo.progressList.length > 0 && progressInfo"
      >
        <div
          :class="[$style.item, $style[statusEnum[item.status].status]]"
          v-for="(item, index) in progressInfo.progressList"
          :key="index"
        >
          <div :class="$style.line" v-if="index > 0"></div>
          <strong :title="item.engineeringStage">{{
            item.engineeringStage
          }}</strong>
          <div :class="$style.status">
            <a-icon :type="statusEnum[item.status].icon" />
          </div>
          <span>{{
            item.progressStartDate > 0
              ? dayjs(item.progressStartDate).format('YYYY-MM-DD')
              : '--'
          }}</span>
        </div>
      </div>
      <EmptyContent
        v-if="progressInfo.progressList.length === 0 || !progressInfo"
      />
    </div>
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import EmptyContent from '@/components/empty-content';

@Component({
  components: {
    EmptyContent,
  },
})
export default class WorksProgramme extends Vue {
  @Prop({ type: Object, required: true }) progressInfo;
  // exchangeStatus(status) {
  //   switch (status) {
  //     case 'OVER':
  //       return 'finish';
  //     case 'PROCESSING':
  //       return 'process';
  //     case 'NOT_STARTED':
  //       return 'wait';
  //   }
  // }
  statusEnum = {
    OVER: {
      status: 'finish',
      icon: 'check',
    },
    PROCESSING: {
      status: 'process',
      icon: 'minus',
    },
    NOT_STARTED: {
      status: 'wait',
      icon: '',
    },
  };
}
</script>
<style lang="less" module>
.works {
  padding: 0.1rem 0.1rem;
  height: 100%;
  .time {
    display: flex;
    height: 35%;
    .infoItem {
      width: 48%;
      margin-bottom: 0.08rem;
      .name {
        display: inline-block;
        padding: 0 0.1rem;
        margin-bottom: 0.06rem;
        text-align: center;
        line-height: 0.24rem;
        font-size: 0.13rem;
        color: var(--font-sub);
        background: var(--tag);
        border-radius: 0.12rem;
        border: 1px solid var(--border);
      }
      .text {
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        max-height: 0.48rem;
        font-size: 0.15rem;
        font-weight: 600;
        color: var(--font);
      }
    }
  }
  .programme {
    margin-top: 0.15rem;
    overflow-x: auto;
    overflow-y: hidden;
    height: 60%;
    width: 100%;
    .steps {
      width: 100%;
      white-space: nowrap;
      .item {
        display: inline-block;
        position: relative;
        margin-left: 20px;
        width: 130px;
        & > strong {
          font-weight: bold;
          font-size: 0.16rem;
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 1rem;
        }
        & > span {
          font-size: 0.14rem;
          color: var(--font);
        }
        .status {
          height: 20px;
          width: 20px;
          margin: 0.04rem 0;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          color: #ffffff;
        }
        .line {
          display: flex;
          align-items: center;
          margin-left: -130px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 0;
          width: 100%;
        }
        .line::after {
          display: inline-block;
          width: 100%;
          height: 2px;
          border-radius: 1px;
          content: '';
        }
      }
      .item:first-of-type {
        margin-left: 0;
      }
      .finish {
        .status {
          font-size: 14px;
          background-color: #4fa2ff;
        }
        .line::after {
          background: #4fa2ff;
        }
      }
      .process {
        .status {
          background-color: #00c46d;
        }
        .line::after {
          background: #00c46d;
        }
      }
      .wait {
        .status {
          border: 1px solid #f0f0f0;
          background: #fafbfc;
        }
        .line::after {
          background: #f0f0f0;
        }
      }
    }
    // .title,
    // .description {
    //   display: inline-block;
    //   overflow: hidden;
    //   text-overflow: ellipsis;
    //   line-height: 14px;
    //   white-space: nowrap;
    //   width: 1rem;
    // }
    // .title {
    //   font-weight: 800;
    // }
  }
}
// :global(.ant-steps-item-finish .ant-steps-item-icon, ) {
//   margin-top: 0 !important;
//   margin-bottom: 0 !important;
//   box-shadow: none;
// }
// :global(.ant-steps-item-process .ant-steps-item-icon) {
//   margin-top: 0 !important;
//   margin-bottom: 0 !important;
//   box-shadow: none;
// }
</style>
