<template>
  <div :class="$style.mainContent">
    <div :class="$style.topLists">
      <div :class="$style.item">
        <img src="@/assets/images/theme/pm10.svg" />
        <div :class="$style.desc">
          <span>PM10</span>
          <P>{{ data.pm10 === -1 ? '-' : `${data.pm10}` }}µg/m³</P>
        </div>
        <!-- <img :class="$style.tipIcon" src="@/assets/images/theme/up-icon.svg" /> -->
      </div>
      <div :class="$style.item">
        <img src="@/assets/images/theme/pm2.5.svg" />
        <div :class="$style.desc">
          <span>PM2.5</span>
          <P>{{ data.pm25 === -1 ? '-' : `${data.pm25}` }}µg/m³</P>
        </div>
        <!-- <img :class="$style.tipIcon" src="@/assets/images/theme/dn-icon.svg" /> -->
      </div>
      <div :class="$style.item">
        <img src="@/assets/images/theme/tsp.svg" />
        <div :class="$style.desc">
          <span>TSP</span>
          <P
            >{{
              data.particulates === -1 ? '-' : `${data.particulates}`
            }}µg/m³</P
          >
        </div>
      </div>
      <div :class="$style.item">
        <img src="@/assets/images/theme/qy.svg" />
        <div :class="$style.desc">
          <span>气压</span>
          <P>{{ data.pressure === -1 ? '-' : `${data.pressure}` }}Pa</P>
        </div>
      </div>
    </div>
    <ul :class="$style.midLists">
      <li>
        <h3>{{ data.windSpeed === -1 ? '-' : `${data.windSpeed}` }}m/s</h3>
        <p>风速</p>
      </li>
      <li>
        <h3>{{ data.humidity === -1 ? '-' : `${data.humidity}` }}%</h3>
        <p>湿度</p>
      </li>
      <li>
        <h3>{{ data.noise === -1 ? '-' : `${data.noise}` }}dB</h3>
        <p>噪音</p>
      </li>
      <li>
        <h3>{{ getWindDirection }}</h3>
        <p>风向</p>
      </li>
      <li>
        <h3>{{ data.temperature === -1 ? '-' : `${data.temperature}` }}℃</h3>
        <p>温度</p>
      </li>
    </ul>
    <p :class="$style.updateTime">最后更新时间：{{ lastUpdateTime }}</p>
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { swindDirection } from '../../../../utils';
@Component({
  components: {},
})
export default class DustMonitoringData extends Vue {
  @Prop({
    type: Object,
    default: () => {},
  })
  data;
  get lastUpdateTime() {
    return this.data.lastTime
      ? this.dayjs(this.data.lastTime).format('YYYY-MM-DD HH:mm:ss')
      : '-';
  }
  get getWindDirection() {
    return this.data.windDirection === -1
      ? '无'
      : swindDirection(this.data.windDirection);
  }
}
</script>

<style lang="less" module>
.mainContent {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  height: 100%;
  .topLists {
    display: grid;
    grid-template-columns: repeat(2, 40%);
    grid-template-rows: repeat(2, 40%);
    // width: 70%;
    margin: 0 auto;
    flex-shrink: 0;
    grid-column-gap: 0.9rem;
    grid-row-gap: 0.1rem;
    .item {
      display: flex;
      .desc {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-left: 0.2rem;
        span {
          font-size: 0.12rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #d8e1f3;
        }
        p {
          margin: 0.1rem auto 0;
          font-size: 0.18rem;
          font-family: D-DIN;
          font-weight: 700;
          color: #fcffff;
          line-height: 0px;
          text-shadow: 0px 0px 7px #1f69c8, 0px 0px 10px #7db6ff;
        }
      }
      .tipIcon {
        margin-left: 0.2rem;
        margin-top: 0.2rem;
        height: 0.26rem;
        width: 0.16rem;
      }
    }
  }
  .midLists {
    display: flex;
    list-style: none;
    justify-content: center;
    align-items: center;
    width: 70%;
    margin: 0.02rem auto 0.05rem;
    padding: 0;
    flex-shrink: 0;
    li {
      flex: 1;
      text-align: center;
      h3 {
        font-size: 0.16rem;
        font-family: D-DIN;
        font-weight: 700;
        color: #fcffff;
        margin: 0;
        text-shadow: 0px 0px 7px #1f69c8, 0px 0px 10px #7db6ff;
      }
      p {
        font-size: 0.12rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #d8e1f3;
        margin: 0;
      }
    }
  }
  .updateTime {
    flex-shrink: 0;
    text-align: center;
    font-size: 0.12rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #d8e1f3;
    line-height: 0.13rem;
    margin-top: 0;
  }
}
</style>
