<template>
  <div class="project-lists-container">
    <a-carousel :dots="false" :arrows="projectDescList.length > 1" autoplay>
      <div
        slot="prevArrow"
        class="custom-slick-arrow"
        style="left: 10px;zIndex: 1"
      >
        <a-icon type="left-circle" />
      </div>
      <div slot="nextArrow" class="custom-slick-arrow" style="right: 10px">
        <a-icon type="right-circle" />
      </div>
      <div
        class="project-lists-item"
        v-for="(item, index) in projectDescList"
        :key="index"
      >
        <ul class="projetc-list-main">
          <li v-for="(subItem, sIndex) in item" :key="sIndex">
            <h2>{{ subItem.name }}:</h2>
            <p>
              {{ subItem.value }}
            </p>
          </li>
        </ul>
      </div>
      <div v-for="(item, index) in projectImgList" :key="index">
        <x-oss-image
          :key="index"
          basePath="/oss/oss"
          :ossPath="item.value"
          class="carousel-img"
          @click="openImg(imgLists, index)"
        />
      </div>
    </a-carousel>
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { chunk } from 'lodash';
import { UploadService } from '@triascloud/services';
import { Preview } from '@triascloud/x-components';
@Component({})
export default class ProjectInfoLists extends Vue {
  @Prop({
    type: Object,
    default: () => {},
  })
  data;

  get imgLists() {
    return this.projectImgList.map(ele => ele.value);
  }
  get projectDescList() {
    const descArr = [];
    for (const key in this.data) {
      //eslint-disable-next-line
      if (this.data.hasOwnProperty(key) && typeof this.data[key] != 'object') {
        switch (key) {
          case 'projectName':
            descArr.push({
              name: '项目名称',
              value: this.data[key],
            });
            break;
          case 'projectAddress':
            descArr.push({
              name: '项目地址',
              value: this.data[key],
            });
            break;
          case 'projectBuildCompany':
            descArr.push({
              name: '建设单位',
              value: this.data[key],
            });
            break;
          case 'projectCompletedValue':
            descArr.push({
              name: '已完成产值(万元)',
              value: `${this.data[key] === -1 ? '-' : this.data[key]}`,
            });
            break;
          case 'projectCompletionDate':
            descArr.push({
              name: '竣工日期',
              value: this.formatTime(this.data[key]),
            });
            break;
          case 'projectCost':
            descArr.push({
              name: '工程造价(万元)',
              value: `${this.data[key] === -1 ? '-' : this.data[key]}`,
            });
            break;
          case 'projectDuration':
            descArr.push({
              name: '工程工期',
              value: `${this.data[key]}天`,
            });
            break;
          case 'projectEstimate':
            descArr.push({
              name: '工程量估算(天)',
              value: `${this.data[key] === -1 ? '-' : this.data[key]}`,
            });
            break;
          case 'projectIntroduction':
            descArr.push({
              name: '项目介绍',
              value: this.data[key],
            });
            break;
          case 'projectNumber':
            descArr.push({
              name: '项目编号',
              value: this.data[key],
            });
            break;
          case 'projectProperties':
            descArr.push({
              name: '工程性质',
              value: this.data[key],
            });
            break;
          case 'projectStartDate':
            descArr.push({
              name: '开工日期',
              value: this.formatTime(this.data[key]),
            });
            break;
          case 'projectState':
            descArr.push({
              name: '项目状态',
              value: this.data[key],
            });
            break;
          case 'projectSupervisionCompany':
            descArr.push({
              name: '监理单位',
              value: this.data[key],
            });
            break;
          case 'projectTotalAmount':
            descArr.push({
              name: '合同总额(万元)',
              value: `${this.data[key] === -1 ? '-' : this.data[key]}`,
            });
            break;
          case 'projectType':
            descArr.push({
              name: '工程类型',
              value: this.data[key],
            });
            break;
          default:
            break;
        }
      }
    }
    return chunk(descArr, 6);
  }
  get projectImgList() {
    return this.data && this.data.projectPictures;
  }
  mounted() {}
  formatTime(t) {
    return t ? this.dayjs(t).format('YYYY-MM-DD') : '-';
  }

  async openImg(pictures) {
    const basePath = '/oss/oss';
    const customService = new UploadService(basePath);
    const abPath = await customService.getAuth(pictures);
    Preview.createModal(abPath);
  }
}
</script>
<style lang="less" scoped>
.project-lists-item {
  .projetc-list-main {
    list-style: none;
    display: grid;
    padding: 0;
    margin: 0.04rem 0.16rem;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 0.2rem;
    min-height: 100%;
    li {
      width: 100%;
      text-align: left;
      margin-bottom: 0.08rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      h2,
      p {
        padding: 0;
        margin: 0;
        font-weight: 400;
        font-family: Source Han Sans;
        font-size: 0.12rem;
      }
      h2 {
        color: #8c98b1;
      }
      p {
        color: #e1e8f5;
        line-height: 24px;
        overflow: hidden;
        text-overflow: ellipsis; /* 超出部分省略号 */
        word-break: break-all; /* break-all(允许在单词内换行。) */
        display: -webkit-box; /* 对象作为伸缩盒子模型显示 */
        -webkit-box-orient: vertical; /* 设置或检索伸缩盒对象的子元素的排列方式 */
        -webkit-line-clamp: 2; /* 显示的行数 */
      }
    }
  }
}
</style>
<style scoped>
.project-lists-container,
.ant-carousel {
  height: 100%;
}
.carousel-img {
  width: 100%;
  object-fit: cover;
  height: 100%;
}
/* For demo */
.ant-carousel >>> .slick-slide {
  height: 100%;
  overflow: hidden;
}

.ant-carousel >>> .custom-slick-arrow {
  width: 25px;
  height: 25px;
  font-size: 25px;
  color: #fff;
  background-color: rgba(31, 45, 61, 0.11);
  opacity: 0.3;
}
.ant-carousel >>> .custom-slick-arrow:before {
  display: none;
}
.ant-carousel >>> .custom-slick-arrow:hover {
  opacity: 0.5;
}
</style>
