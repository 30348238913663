import { request, serviceName, oldServiceName } from './base-config';

// 获取项目信息
export function projectStatisticsInfo(data) {
  return request(`${serviceName}/screen/home/project`, {
    method: 'GET',
    body: data,
  });
}

//获取劳务人员状态
export function getWorkerStatus(data) {
  return request(`${serviceName}/screen/home/worker/state`, {
    method: 'GET',
    body: data,
  });
}

//隐患问题分析
export function getDangerProblem(days) {
  return request(`${serviceName}/screen/home/danger/state/count`, {
    method: 'GET',
    body: days,
  });
}

//巡检问题分析
export function getInspectionProblem(days) {
  return request(`${serviceName}/screen/home/inspection/state/count`, {
    method: 'GET',
    body: days,
  });
}

//设备报警
export function getEquipmentAlarm(days) {
  return request(`${serviceName}/screen/home/inspection/state/count`, {
    method: 'GET',
    body: days,
  });
}

//扬尘检测
export function getDustMonitor(data) {
  return request(`${serviceName}/screen/home/dust/monitor`, {
    method: 'GET',
    body: data,
  });
}

//项目进度
export function getProjectProgress(data) {
  return request(`${serviceName}/screen/home/project/progress`, {
    method: 'GET',
    body: data,
  });
}

//查询设备的列表    smart-site/device/dust/search/list
export function getDustDevicesLists(data) {
  return request(`${oldServiceName}/device/dust/search/list`, {
    method: 'POST',
    body: data,
  });
}

//项目里程碑
export function getProjectMilestone(data) {
  return request(`${serviceName}/screen/home/project/milestone`, {
    method: 'GET',
    body: data,
  });
}

// 设备报警-分页列表
export function getDevicesAlarmLists(data) {
  return request(`${oldServiceName}/screen/alarm/record/list`, {
    method: 'POST',
    body: data,
  });
}
