<template>
  <div>
    <a-table
      :columns="columns"
      :data-source="data"
      :pagination="pagination"
      :scroll="{ y: 400 }"
      :loading="loading"
      @change="changeTableData"
    ></a-table>
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
@Component({
  components: {},
})
export default class EquipmentAlarmInfo extends Vue {
  @Prop({ type: Array, default: () => [] }) data;
  @Prop({ type: Number, default: 1 }) current;
  @Prop({ type: Number, default: 0 }) total;
  @Prop({ type: Boolean, default: false }) loading;
  columns = [
    {
      title: '序号',
      dataIndex: 'index',
      width: 60,
      key: 'index',
      customRender(text, record, index) {
        return index + 1;
      },
    },
    {
      title: '设备名称',
      dataIndex: 'deviceName',
      key: 'deviceName',
    },
    {
      title: '设备码',
      dataIndex: 'deviceCode',
      key: 'deviceCode',
    },
    {
      title: '设备类型',
      dataIndex: 'deviceGroup',
      key: 'deviceGroup',
      ellipsis: true,
    },
    {
      title: '报警信息',
      dataIndex: 'alarmInfo',
      key: 'alarmInfo',
      ellipsis: true,
    },
    {
      title: '报警时间',
      dataIndex: 'alarmTime',
      key: 'alarmTime',
      ellipsis: true,
      customRender: text => this.formatTime(text),
    },
  ];
  pagination = {
    total: 0,
    showSizeChanger: true,
    pageSize: 10,
    current: 1,
    size: 'small',
    pageSizeOptions: ['10', '20', '30', '50'],
  };
  created() {
    this.pagination.total = this.total;
    this.pagination.current = this.current;
  }
  changeTableData(pagination) {
    this.pagination = pagination;
    this.$emit('changeTablePage', pagination);
  }
  formatTime(t) {
    return t ? this.dayjs(t).format('YYYY-MM-DD HH:mm:ss') : '-';
  }
}
</script>
<style lang="less" scoped></style>
