<template>
  <div :class="$style.wrapper">
    <img :src="icon" :alt="title" />
    <div :class="$style.info">
      <span :class="$style.title">{{ title }}</span>
      <span :class="$style.content">{{ content }}</span>
    </div>
  </div>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import AliIcon from '@/components/ali-icon/index.vue';

@Component({
  components: { AliIcon },
})
export default class IconItem extends Vue {
  @Prop({ type: String }) icon;
  @Prop({ type: String }) title;
  @Prop({ type: String }) content;
}
</script>

<style lang="less" module>
.wrapper {
  position: relative;
  top: 0;
  left: 0;
  img {
    width: 1.94rem;
  }
  .info {
    position: absolute;
    top: 0.22rem;
    left: 0.7rem;
    display: flex;
    align-items: center;

    .title {
      display: block;
      padding-right: 0.08rem;
      font-size: 0.12rem;
      color: #9ac0d9;
    }
    .content {
      padding-top: 0.03rem;
      font-size: 0.16rem;
      color: #febd7d;
    }
  }
}
</style>
