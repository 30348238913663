<template>
  <div :class="$style['label-content']">
    <ul :class="$style.itemLists">
      <li :class="$style.item">
        <h3>{{ data.builderNum }}</h3>
        <p>建筑工人</p>
      </li>
      <li :class="$style.item">
        <h3>{{ data.adminNum }}</h3>
        <p>管理人员</p>
      </li>
      <li :class="$style.item">
        <h3>{{ data.safeNum }}</h3>
        <p>安管人员</p>
      </li>
      <li :class="$style.item">
        <h3>{{ data.todayAttendanceNum }}</h3>
        <p>今日出勤人员</p>
      </li>
      <li :class="$style.item">
        <h3>{{ data.todayPresenceNum }}</h3>
        <p>今日在场人员</p>
      </li>
      <li :class="$style.item">
        <h3>{{ changeData(data.todayAttendanceRate) }}</h3>
        <p>出勤率</p>
      </li>
    </ul>
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
@Component({})
export default class LaborStatus extends Vue {
  @Prop({
    type: Object,
    default: () => {},
  })
  data;

  changeData(t) {
    return t >= 0 ? `${t}%` : '-';
  }
}
</script>

<style lang="less" module>
.label-content {
  padding: 0.06rem 0.6rem;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  height: 100%;
  // background: linear-gradient(
  //   180deg,
  //   #071c38 9%,
  //   rgba(17, 31, 47, 0.5) 43%,
  //   rgba(0, 0, 0, 0) 91%
  // );
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(5px);
  .itemLists {
    list-style: none;
    overflow: hidden;
    padding: 0;
    box-sizing: border-box;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 0;
    align-items: center;

    .item {
      width: 0.97rem;
      height: 0.88rem;
      margin: 0 0 0 0.14rem;
      background: linear-gradient(
        270deg,
        rgba(76, 175, 255, 0) 0%,
        rgba(76, 175, 255, 0.08) 52%,
        rgba(76, 175, 255, 0) 100%
      );
      display: flex;
      align-items: center;
      flex-direction: column;

      h3 {
        margin-top: 0.1rem;
        font-size: 0.24rem;
        font-family: 'Microsoft YaHei';
        font-weight: 700;
        color: #fcffff;
        text-shadow: 0px 0px 7px #1f69c8, 0px 0px 10px #7db6ff;
        background: url('~@/assets/images/theme/labor-icon.png') no-repeat
          center bottom;
        width: 0.7rem;
        background-size: 100%;
        padding-bottom: 0.08rem;
      }
      p {
        margin: 0;
        font-size: 0.14rem;
        font-family: D-DIN-Bold;
        font-weight: 700;
        color: #d8e1f3;
      }
    }
  }
}
</style>
