<template>
  <LayoutCard :title="$t('projectView.projectInformation')">
    <template #action>
      <LayoutTabs
        v-model="activeIndex"
        :options="[
          { key: 0, text: $t('projectView.intro') },
          { key: 1, text: $t('projectView.worksProgramme') },
        ]"
      />
    </template>

    <div :class="$style.wrapper" v-if="activeIndex === 0">
      <div :class="$style.top">
        <IconItem
          v-for="(item, index) in info"
          :key="index"
          :title="item.name"
          :content="item.text"
          :icon="item.icon"
        />
      </div>
      <div :class="$style.bottom">
        <div :class="$style.bottomLeft">
          <a-carousel
            v-if="projectInfo.projectPhotos.length"
            :class="$style.images"
            :dots="false"
            autoplay
          >
            <x-oss-image
              v-for="(item, index) in projectInfo.projectPhotos"
              :key="index"
              :ossPath="item"
              :class="$style.image"
            />
          </a-carousel>
          <div v-else :class="$style.images">
            <img
              src="@/assets/images/theme/empty.png"
              :class="$style.emptyImage"
            />
          </div>
        </div>
        <div :class="$style.info">
          <div :class="$style.infoLabel">
            {{ $t('projectView.projectAddress') }}
          </div>
          <div :class="$style.infoContent">
            {{ projectInfo.projectLocation }}
          </div>
          <div :class="$style.infoLabel">
            {{ $t('projectView.constructionUnit') }}
          </div>
          <div :class="$style.infoContent">
            {{ projectInfo.constructionUnit }}
          </div>
          <span :class="$style.infoMore" @click="viewIntroduce"
            >[查看工程简介]</span
          >
        </div>
      </div>
    </div>

    <works-programme v-if="activeIndex === 1" :progressInfo="progressInfo" />
  </LayoutCard>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import WorksProgramme from './works-programme.vue';
import { projectInfo } from '@/services/screen/screen';
import { LayoutTabs, LayoutCard } from '@/views/screen/layout';
import IconItem from './components/icon-item.vue';
import { createModal, Editor } from '@triascloud/x-components';
@Component({
  components: {
    WorksProgramme,
    LayoutCard,
    LayoutTabs,
    IconItem,
  },
})
export default class ProjectInfo extends Vue {
  mounted() {
    this.getProjectInfo();
  }

  activeIndex = 0;

  info = [
    {
      icon: require('@/assets/images/theme/project/status.png'),
      name: this.$t('projectView.projectStatus'),
      value: 'projectStatus',
      text: '--',
    },
    {
      icon: require('@/assets/images/theme/project/cost.png'),
      name: this.$t('projectView.projectCost'),
      value: 'projectCosts',
      text: '--',
    },
    // {
    //   name: this.$t('projectView.projectAddress'),
    //   value: 'detailAddress',
    //   text: '--',
    // },
    // {
    //   name: this.$t('projectView.constructionUnit'),
    //   value: 'constructionUnit',
    //   text: '--',
    // },
    // {
    //   name: this.$t('projectView.quantityEstimation'),
    //   value: 'quantityEstimation',
    //   text: '--',
    // },
  ];

  get projectId() {
    return this.$route.params.projectId;
  }
  progressInfo = [];
  async getProjectInfo() {
    try {
      const data = await projectInfo(this.projectId);
      this.progressInfo = data.progressInfoVO;
      this.projectInfo = data;
      this.info.forEach(e => {
        if (e.value === 'projectStatus') {
          e.text = this.exchangeValue(data[e.value]) || '';
        } else {
          e.text = data[e.value] || '--';
        }
      });
    } catch {
      return false;
    }
  }
  exchangeValue(t) {
    switch (t) {
      case 'NOT_STARTED':
        return '未开始';
      case 'CONSTRUCTION':
        return '施工中';
      case 'COMPLETED':
        return '已完工';
    }
  }

  viewIntroduce() {
    createModal(
      () => <Editor value={this.projectInfo.projectIntroduce} view />,
      {
        title: '工程简介',
        width: '8rem',
      },
    );
  }
}
</script>
<style lang="less" module>
.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0.16rem;
  justify-content: space-between;
  overflow: hidden;
  .top {
    display: flex;
    justify-content: space-between;
  }
  .bottom {
    display: flex;
    flex-shrink: 1;
    overflow: hidden;
    .bottomLeft {
      position: relative;
      width: 50%;
      background-image: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 9%,
        rgba(17, 31, 47, 0.5) 43%,
        #071c38 91%
      );
      margin-right: 0.16rem;
      border: 1px solid #9ac0d9;
      overflow: hidden;
      &::after {
        content: '';
        padding-bottom: 65%;
        width: 100%;
        height: 1px;
        display: inline-block;
      }
      :global {
        .ant-carousel {
          .slick-slider {
            height: 100%;
            .slick-list {
              height: 100%;
              .slick-track {
                height: 100%;
                .slick-slide {
                  height: 100%;
                  & > div {
                    height: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }
    .images {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      .image {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .info {
      flex: 1;
      line-height: 1.6;
      font-size: 0.12rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .infoLabel {
        color: #90a1b1;
      }
      .infoContent {
        color: #9ac0d9;
      }
      .infoMore {
        display: inline-block;
        color: #37d7db;
        margin-top: 0.1rem;
        cursor: pointer;
      }
    }
    .emptyImage {
      height: 0.7rem;
      width: auto;
      position: absolute;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%;
    }
  }
}
</style>
