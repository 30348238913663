<template>
  <div :class="$style.analysPieBox">
    <div :class="$style.analysPie">
      <PieChart :chartData="option" width="2.2rem" height="2.2rem" />
    </div>
  </div>
</template>

<script>
import { Vue, Prop, Watch, Component } from 'vue-property-decorator';
// import PieChart from '../pieChart';
import PieChart from '@/views/screen/e-charts/pie-chart.vue';
@Component({
  components: {
    PieChart,
  },
})
export default class AnalysisIssues extends Vue {
  @Prop({
    required: true,
    type: Object,
    default: () => {},
  })
  config;
  @Watch('config', { deep: true, immediate: true })
  watchConfig() {
    // console.log('val: ', val);
  }
  created() {
    // console.log('DustMonitoringData');
  }

  option = [
    { value: 40, name: '待整改' },
    { value: 20, name: '已整改' },
    { value: 20, name: '待审核' },
  ];

  // option = {
  //   tooltip: {
  //     trigger: 'item',
  //   },
  //   title: {
  //     zIndex: 1,
  //     text: ['{name|总}', '{value|' + 80 + '}'].join('\n'),
  //     top: '35%',
  //     left: '48%',
  //     textAlign: 'center',
  //     textStyle: {
  //       rich: {
  //         value: {
  //           color: '#FCFFFF',
  //           fontSize: 24,
  //           textShadowColor: '#1F69C8',
  //           textShadowOffsetX: 0,
  //           textShadowOffsetY: 0,
  //           textShadowBlur: 7,
  //         },
  //         name: {
  //           color: '#9AC0D9',
  //           fontSize: 14,
  //           lineHeight: 25,
  //         },
  //       },
  //     },
  //   },
  //   // graphic: [
  //   //   {
  //   //     type: 'image',
  //   //     id: 'logo',
  //   //     left: '1.444rem',
  //   //     top: '.2rem',
  //   //     z: -10,
  //   //     bounding: 'raw',
  //   //     style: {
  //   //       image: require('@/assets/images/theme/ring-bg.png'),
  //   //       width: '1.8rem',
  //   //       //height: 150,
  //   //       opacity: 0.4,
  //   //     },
  //   //   },
  //   // ],
  //   // legend: {
  //   //   orient: 'vertical',
  //   //   left: '50%',
  //   //   top: 50,
  //   //   y: 'center',
  //   //   icon: 'circle',
  //   //   itemGap: 25,
  //   //   textStyle: {
  //   //     color: '#D8E1F3',
  //   //   },
  //   //   formatter(name) {
  //   //     // let data = _this.getNum(name);
  //   //     var arr = ['{a|' + name + '}', '{b|' + 40 + '}'];
  //   //     return arr.join(' ');
  //   //   },
  //   // },
  //   // textStyle: {
  //   //   padding: [8, 0, 0, 0],
  //   //   rich: {
  //   //     a: {
  //   //       fontSize: 15,
  //   //       width: 110,
  //   //     },
  //   //     b: {
  //   //       fontSize: 15,
  //   //       width: 70,
  //   //       color: '#c1c1c1',
  //   //     },
  //   //   },
  //   // },
  //   series: [
  //     {
  //       name: '',
  //       type: 'pie',
  //       radius: ['40%', '70%'],
  //       color: ['#febd7d', '#fff', '#37d7db'],
  //       avoidLabelOverlap: false,
  //       itemStyle: {
  //         // borderRadius: 10,
  //         // borderColor: `var(--primary)`,
  //         // borderWidth: 4,
  //       },
  //       label: {
  //         show: false,
  //         position: 'center',
  //       },
  //       emphasis: {
  //         label: {
  //           show: true,
  //           fontSize: 20,
  //           fontWeight: 'bold',
  //         },
  //       },
  //       labelLine: {
  //         show: false,
  //       },
  //       data: this.pieData,
  //     },
  //   ],
  // };
}
</script>

<style lang="less" module>
.analysPieBox {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  background: linear-gradient(
    180deg,
    #071c38 9%,
    rgba(17, 31, 47, 0.5) 43%,
    rgba(0, 0, 0, 0) 91%
  );
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(5px);

  .analysPie {
    width: 35%;
    height: 100%;
    box-sizing: border-box;
    margin-left: 0.43rem;
    display: flex;
    align-items: center;
  }
  .pieDesc {
    list-style: none;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 0;
    li {
      margin-bottom: 0.1rem;
      display: flex;
      align-items: center;
      i {
        display: inline-block;
        width: 0.08rem;
        height: 0.08rem;
        border-radius: 2px 2px 2px 2px;
        border-radius: 50%;
      }
      span {
        &:nth-of-type(1) {
          font-size: 0.18rem;
          font-family: 'Microsoft YaHei';
          font-weight: 400;
          color: #d8e1f3;
          line-height: 0.18rem;
          margin-left: 0.2rem;
        }
        &:last-of-type {
          font-size: 0.2rem;
          font-family: D-DIN;
          font-weight: 700;
          margin-left: 0.6rem;
          color: #fcffff;
          text-shadow: 0px 0px 7px #1f69c8, 0px 0px 10px #7db6ff;
        }
      }
      &:nth-child(1) {
        i {
          background: #febd7d;
        }
      }
      &:nth-child(2) {
        i {
          background: #fff;
        }
      }
      &:nth-child(3) {
        i {
          background: #37d7db;
        }
      }
    }
  }
}
</style>
