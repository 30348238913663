<template>
  <div :class="$style.wrapper">
    <div :class="$style.item1">
      <LayoutCard
        :class="$style.card"
        :title="$t('projectView.projectInformation')"
      >
        <!-- <template slot="action">
          <div :class="$style.action">
            <span @click="sortHandle">排序</span>
            <img
              src="@/assets/images/theme/sort-icon.svg"
              :class="$style.sortIcon"
            />
          </div>
        </template> -->
        <ProjectInfoLists
          v-if="projectInfoListsData"
          :data="projectInfoListsData"
        />
        <div :class="$style['empty-height']" v-else>
          <EmptyContent />
        </div>
      </LayoutCard>
    </div>
    <div :class="$style.item2">
      <LayoutCard :class="$style.card" :showHeader="false">
        <ProjectConceptPic
          v-if="projectConceptPicData.length"
          :data="projectConceptPicData"
        />
        <EmptyContent v-else />
      </LayoutCard>
    </div>
    <div :class="$style.item3">
      <LayoutCard
        :class="$style.card"
        :title="$t('screen.index.dustMonitoringData')"
      >
        <template slot="action">
          <LayoutSelect
            v-if="equipmentList.length"
            :options="equipmentList"
            :defaultValue="dustMonitorId"
            @change="val => handleSelect(val, 'dustMonitor')"
            style="width:180px;"
          ></LayoutSelect>
        </template>
        <DustMonitoringComp
          v-if="Object.keys(dustMonitoringCompData).length"
          :data="dustMonitoringCompData"
        />
        <div :class="$style['empty-height']" v-else>
          <EmptyContent />
        </div>
      </LayoutCard>
    </div>
    <div :class="$style.item4">
      <LayoutCard :class="$style.card" :title="$t('screen.index.laborStatus')">
        <LaborStatus
          v-if="Object.keys(laborStatusConfig).length"
          :data="laborStatusConfig"
        />
        <div :class="$style['empty-height']" v-else>
          <EmptyContent />
        </div>
      </LayoutCard>
    </div>
    <div :class="[$style.item5]">
      <LayoutCard
        :class="$style.card"
        :title="$t('screen.index.hazardAnalysis')"
      >
        <template slot="action">
          <LayoutSelect
            :options="dateList"
            defaultValue="SEVEN_DAY"
            @change="val => handleSelect(val, 'dangerProblem')"
          ></LayoutSelect>
        </template>
        <div
          class="h-full overflow-hidden"
          style="padding: 0.16rem"
          v-if="dangerProblemAnalysisData.length"
        >
          <PieChart
            :colorArray="['#FEBD7D', '#FFFFFF', '#37D7DB']"
            :chartData="dangerProblemAnalysisData"
            :labelOption="dangerProblemTotalLabelOption"
            right="30px"
          ></PieChart>
        </div>
        <div :class="$style['empty-height']" v-else>
          <EmptyContent />
        </div>
      </LayoutCard>
    </div>
    <div :class="[$style.item6]">
      <LayoutCard
        :class="$style.card"
        :title="$t('screen.spotChecking.analysisInspectionIssues')"
      >
        <template slot="action">
          <LayoutSelect
            :options="dateList"
            defaultValue="SEVEN_DAY"
            @change="val => handleSelect(val, 'inspectionIssues')"
          ></LayoutSelect>
        </template>
        <div
          class="h-full overflow-hidden"
          style="padding: 0.16rem"
          v-if="inspectionProblemData.length"
        >
          <PieChart
            :colorArray="['#FEBD7D', '#FFFFFF', '#37D7DB']"
            :chartData="inspectionProblemData"
            :labelOption="inspectionProblemTotalLabelOption"
            right="30px"
          ></PieChart>
        </div>
        <div :class="$style['empty-height']" v-else>
          <EmptyContent />
        </div>
      </LayoutCard>
    </div>
    <div :class="$style.item7">
      <LayoutCard
        :class="$style.card"
        :title="$t('screen.index.equipmentAlarm')"
      >
        <ScrollTable
          v-if="scrollTbaleData.length"
          :columns="scrollTbaleColumns"
          :data="copyTopTenData"
          @cellClick="cellClick"
        ></ScrollTable>
        <div :class="$style['empty-height']" v-else>
          <EmptyContent />
        </div>
      </LayoutCard>
    </div>
    <div :class="$style.item8">
      <LayoutCard
        :class="$style.card"
        :title="$t('screen.index.projectMilestones')"
      >
        <template #action>
          <p :class="$style.timeCount">项目倒计时：{{ projectRemainTime }}天</p>
        </template>
        <div
          :class="$style.bContent"
          ref="bCharts"
          id="bCharts"
          v-if="progressBarData.length > 0"
        >
          <ProjectMilestones :progressBarData="progressBarData" />
        </div>
        <div :class="$style['empty-height']" v-else>
          <EmptyContent />
        </div>
      </LayoutCard>
    </div>
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import ProjectInfo from './components/project-info.vue';
import AnalysisIssues from './comps/analysisIssues';
import { LayoutCard, LayoutSelect } from '../../layout';
import ProjectMilestones from './comps/project-milestones';
import LaborStatus from './comps/laborStatus';
import EmptyContent from '@/components/empty-content';
import ScrollTable from '@/views/screen/components/scroll-table.vue';
import DustMonitoringComp from './comps/dustMonitoringData';
import EquipmentAlarmInfo from './comps/equipmentAlarmInfo';
import { createModal } from '@triascloud/x-components';
import { PublicSize } from '@/views/green-construction/components/utils';
import PieChart from '@/views/screen/e-charts/pie-chart.vue';
import ProjectInfoSortDialog from './components/project-Info-sortDialog.vue';
import ProjectInfoLists from './comps/projectInfoLists/index.vue';
import ProjectConceptPic from './comps/projectConceptPic';
import {
  projectStatisticsInfo,
  getWorkerStatus,
  getDangerProblem,
  getInspectionProblem,
  getDustMonitor,
  getDustDevicesLists,
  getProjectMilestone,
  getDevicesAlarmLists,
} from '@/services/screen/jiangsu/overview';
import { crossStorageModule } from '@/enum/store.js';
import { cloneDeep } from 'lodash';
import { getPieChartTotal, getPieChartDesc } from '../../utils';
import { scrollTbaleColumns } from '../config';
@Component({
  components: {
    ProjectInfo,
    LayoutCard,
    LayoutSelect,
    ProjectMilestones,
    LaborStatus,
    AnalysisIssues,
    EmptyContent,
    ScrollTable,
    DustMonitoringComp,
    EquipmentAlarmInfo,
    PieChart,
    ProjectInfoSortDialog,
    ProjectInfoLists,
    ProjectConceptPic,
  },
})
export default class ProjectOverviewIndex extends Vue {
  @crossStorageModule.State('tenant') tenant;
  get projectId() {
    return this.$store.state.screen.projectId;
  }

  created() {
    this.init();
  }
  init() {
    this.getProjectInfoLists();
    this.getLaborStatus();
    this.getDangerProblemAnaysis();
    this.getInspireProblem();
    this.getProjectMilestoneData();
    this.getDevicesAlarmListsData();
    this.getDustDevicesListsData();
  }
  mounted() {}
  projectInfoListsData = {};
  current = 1;

  //获取项目信息
  projectConceptPicData = [];
  getProjectInfoLists() {
    const params = {
      current: this.current,
      size: 1,
      projectId: this.projectId,
    };
    projectStatisticsInfo(params).then(res => {
      this.projectInfoListsData = res.records[0];
      this.projectConceptPicData =
        res.records?.length && res.records[0].projectConceptPicture;
    });
  }
  //获取设备信息
  equipmentList = [];
  getDustDevicesListsData() {
    const data = {
      device: '',
      projectId: this.projectId,
      query: {
        current: 1,
        size: 100,
      },
    };
    getDustDevicesLists(data).then(res => {
      if (res.records.length) {
        this.equipmentList = res.records.map(ele => ({
          label: this.$t(ele.deviceName),
          value: ele.pkId,
        }));
        this.dustMonitorId =
          this.equipmentList.length && this.equipmentList[0].value;
        this.getDustMonitorData();
      }
    });
  }

  //扬尘数据监测
  dustMonitorId = 0;
  dustMonitoringCompData = {};
  getDustMonitorData() {
    const data = {
      deviceId: this.dustMonitorId,
      projectId: this.projectId,
    };
    getDustMonitor(data).then(res => {
      this.dustMonitoringCompData = res;
    });
  }

  //获取劳动人员状态
  laborStatusConfig = {};
  getLaborStatus() {
    getWorkerStatus({ projectId: this.projectId }).then(res => {
      if (!res || !Object.keys(res).length) return false;
      this.laborStatusConfig = res;
    });
  }

  //隐患问题分析 formatter: `{a|预/报警次数}\n{b|${this.total}}`,
  dangerProblemTotal = 0;
  get dangerProblemTotalLabelOption() {
    return getPieChartDesc(this.dangerProblemTotal);
  }
  dangerProblemAnalysisData = [];
  dangerProblemDays = 'SEVEN_DAY';
  getDangerProblemAnaysis() {
    getDangerProblem({
      days: this.dangerProblemDays,
      projectId: this.projectId,
    }).then(res => {
      const [ret, total] = getPieChartTotal(res);
      this.dangerProblemAnalysisData = ret;
      this.dangerProblemTotal = total;
    });
  }

  //巡检问题分析
  inspectionProblemTotal = 0;
  inspectionProblemData = [];
  inspectionProblemDays = 'SEVEN_DAY';
  getInspireProblem() {
    getInspectionProblem({
      days: this.inspectionProblemDays,
      projectId: this.projectId,
    }).then(res => {
      const [ret, total] = getPieChartTotal(res);
      this.inspectionProblemData = ret;
      this.inspectionProblemTotal = total;
    });
  }

  get inspectionProblemTotalLabelOption() {
    return getPieChartDesc(this.inspectionProblemTotal);
  }
  //设备报警
  scrollTbaleData = [];
  get scrollTbaleColumns() {
    return scrollTbaleColumns;
  }
  current = 1;
  total = 0;
  copyTopTenData;
  size = 10;
  getDevicesAlarmListsData() {
    const data = {
      current: this.current,
      deviceType: '',
      endTime: '',
      queryDetail: true,
      idxTenantId: this.tenant?.pkId,
      keyword: '',
      pkId: '',
      size: this.size,
      startTime: '',
      idxProjectId: this.projectId,
    };
    this.loading = true;
    getDevicesAlarmLists(data)
      .then(res => {
        this.scrollTbaleData = res.records;
        if (this.current === 1) {
          this.copyTopTenData = cloneDeep(res.records);
        }
        this.total = res.total;
        this.current = res.current;
      })
      .finally(() => {
        this.loading = false;
      });
  }
  //切换弹窗警报信息table
  changeTablePage(data) {
    // console.log(data, 'changeTablePage');
    this.current = data.current;
    this.size = data.pageSize;
    this.getDevicesAlarmListsData();
  }
  //项目里程碑
  progressBarData = [
    // {
    //   title: '完成1-2期工程',
    //   time: '2023.06.12',
    //   status: 'start',
    // }
  ];
  projectRemainTime = '';

  getProjectMilestoneData() {
    const data = { projectId: this.projectId };
    getProjectMilestone(data).then(res => {
      this.progressBarData =
        res.length &&
        res[0].projectProcesses.map(ele => ({
          title: ele.stage,
          status: ele.state,
          time: this.formatTime(ele.startDate),
        }));
      this.projectRemainTime =
        res[0]?.projectDaysRemaining > 0 ? res[0]?.projectDaysRemaining : 0;
    });
  }

  formatTime(t) {
    return t ? this.dayjs(t).format('YYYY-MM-DD') : '-';
  }
  get i18n() {
    return this.$t('screen');
  }
  // 分享进入该页面，保证i18n能正常显示
  // @Watch('i18n')
  // i18nChange(val) {
  //   if (val.sevenDay) {
  //     this.waterAndElec = this.dayList();
  //     this.dateList = this.dateFilterList();
  //   }
  // }

  dateFilterList() {
    return [
      {
        label: this.$t('screen.currentDay'),
        value: 'CURRENT_DAY',
      },
      {
        label: this.$t('screen.sevenDay'),
        value: 'SEVEN_DAY',
      },
      {
        label: this.$t('screen.thirtyDay'),
        value: 'THIRTY_DAY',
      },
    ];
  }
  dateList = this.dateFilterList();

  changeChartData(data, obj = {}) {
    return data.map(v => ({
      name: Object.keys(obj).length > 0 ? obj[v.index] : v.index,
      value: v.count,
    }));
  }
  handleSelect(val, flag) {
    if (flag === 'dangerProblem') {
      this.dangerProblemDays = val;
      this.getDangerProblemAnaysis();
    } else if (flag === 'inspectionIssues') {
      this.inspectionProblemDays = val;
      this.getInspireProblem();
    } else if (flag === 'dustMonitor') {
      // console.log(val, 'dustMonitorId');
      this.dustMonitorId = val;
      this.getDustMonitorData();
    }
  }

  dateList = [
    {
      label: this.$t('screen.currentDay'),
      value: 'CURRENT_DAY',
    },
    {
      label: this.$t('screen.sevenDay'),
      value: 'SEVEN_DAY',
    },
    {
      label: this.$t('screen.thirtyDay'),
      value: 'THIRTY_DAY',
    },
  ];
  loading = false;
  cellClick() {
    createModal(
      () => (
        <EquipmentAlarmInfo
          data={this.scrollTbaleData}
          current={this.current}
          total={this.total}
          onChangeTablePage={this.changeTablePage}
          loading={this.loading}
        />
      ),
      {
        title: '设备报警',
        width: PublicSize.MoreLogModalSize,
        closable: true,
        maskClosable: false,
        wrapClassName: 'screen-modal',
      },
    );
  }
  sortHandle() {
    createModal(() => <ProjectInfoSortDialog />, {
      title: '项目信息排序',
      width: PublicSize.MoreLogModalSize,
      closable: true,
      maskClosable: false,
      wrapClassName: 'screen-modal',
    });
  }
}
</script>

<style lang="less" module>
.wrapper {
  display: grid;
  grid-template-rows: repeat(3, 32%);
  grid-template-columns: repeat(12, 1.43rem);
  gap: 0.16rem;
  height: 100%;
}

.item1,
.item2,
.item3 {
  grid-column: span 4;
  overflow-y: hidden;
}
.item4,
.item5,
.item6 {
  grid-column: span 3;
}

.item7 {
  grid-column: span 3;
}

.item8 {
  grid-column: span 12;
}
.chartTitle {
  padding: 0.16rem 0.16rem 0;
  color: #9ac0d9;
  font-size: 0.12rem;
}
.chartHeight {
  height: calc(100% - 0.3rem);
}
.timeCount {
  // width: 2rem;
  margin: 0;
  font-size: 0.14rem;
  font-weight: 700;
  color: #fcffff;
  text-shadow: 0px 0px 7px #1f69c8, 0px 0px 10px #7db6ff;
  margin-right: 0.1rem;
}
.bContent {
  height: 100%;
  display: flex;
  justify-content: center;
}
.topLeft {
  padding-bottom: 0.1rem;
}
.sort {
  font-size: 0.16rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #9ac0d9;
  flex: 4;
  margin: 0;
  padding: 0;
}
.action {
  flex: 4;
  font-size: 0.14rem;
  font-family: 'Microsoft YaHei';
  font-weight: 400;
  color: #9ac0d9;
  line-height: 0.22rem;
  cursor: pointer;
  .sortIcon {
    margin-left: 0.02rem;
  }
}
.empty-height {
  height: 90%;
}
</style>
