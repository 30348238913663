<template>
  <div :class="$style['project-content']">
    <div :class="$style['project-rect']">
      <ul :class="$style['project-rect-lists']">
        <li
          :class="[
            $style['project-rect-item'],
            item.status === '已完成'
              ? $style['start-item']
              : item.status === '进行中'
              ? $style['start-progressing']
              : '',
          ]"
          v-for="(item, index) in progressBarData"
          :key="index"
          :item="item"
        >
          <div v-if="item.status === '已完成'">
            <span></span>
            <div
              :class="[
                $style['desc'],
                index % 2 === 0 ? $style['item-top'] : $style['item-bottom'],
              ]"
            >
              <p :class="$style['desc-title']">
                <!-- <span v-if="item.status === 'start'">入场</span> -->
                <span>{{ item.title }} 已完成</span>
                <!-- <span :class="$style['desc-status']">已完成</span> -->
              </p>
              <p :class="$style['desc-time']">{{ item.time }}</p>
            </div>
            <div :class="$style['start-bar']"></div>
          </div>
          <div v-if="item.status === '进行中'" :class="$style['status-text']">
            <span style="color:#FFEF60">进行中</span>
            <div
              :class="[
                $style['desc'],
                index % 2 === 0 ? $style['item-top'] : $style['item-bottom'],
              ]"
            >
              <p :class="$style['desc-title']">
                <span>{{ item.title }}</span>
              </p>
              <p :class="$style['desc-time']">{{ item.time }}</p>
            </div>
            <div :class="$style['progess-bar']"></div>
          </div>
          <div v-if="item.status === '已逾期'" :class="$style['status-text']">
            <span style="color:#FFEF60">已逾期</span>
            <div
              :class="[
                $style['desc'],
                index % 2 === 0 ? $style['item-top'] : $style['item-bottom'],
              ]"
            >
              <p :class="$style['desc-title']">
                <span>{{ item.title }}</span>
              </p>
              <p :class="$style['desc-time']">{{ item.time }}</p>
            </div>
            <div :class="$style['progess-bar']"></div>
          </div>
          <div v-if="item.status === '未开始'" :class="$style['status-text']">
            <span style="color:#D8E1F3">未开始</span>
            <div
              :class="[
                $style['desc'],
                index % 2 === 0 ? $style['item-top'] : $style['item-bottom'],
              ]"
            >
              <p :class="$style['desc-title']">
                <span>{{ item.title }}</span>
              </p>
              <p :class="$style['desc-time']">{{ item.time }}</p>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
@Component({})
export default class ProjectMilestones extends Vue {
  @Prop({ type: Array, default: () => [] })
  progressBarData;
}
</script>

<style lang="less" module>
.project-content {
  box-sizing: border-box;
  height: 100%;
  display: flex;
  align-items: center;
  overflow-x: auto;
  // margin-top: 0.1rem;
  .project-rect {
    margin: 0.2rem 0.1rem;
    box-sizing: border-box;
    .project-rect-lists {
      list-style: none;
      display: flex;
      margin: 0;
      padding: 0;
      .project-rect-item {
        width: 1.65rem;
        border: 1px solid #77869e;
        height: 0.32rem;
        text-align: center;
        position: relative;
        &.start-item {
          border-top-color: #a8fcff;
          border-left-color: #a8fcff;
          box-shadow: 0px 0px 4px 2px rgba(168, 252, 255, 0.2588);
          &:before {
            content: '';
            position: absolute;
            width: 8px;
            height: 8px;
            background: #a8fcff;
            left: -5px;
            top: -4px;
            border-radius: 50%;
          }
        }
        &.start-progressing {
          border-top-color: #ffef60;
          border-left-color: #ffef60;
          box-shadow: 0px 0px 4px 2px rgba(168, 252, 255, 0.2588);
          &:before {
            content: '';
            position: absolute;
            width: 8px;
            height: 8px;
            background: #ffef60;
            left: -5px;
            top: -4px;
            border-radius: 50%;
          }
        }
        &:before {
          content: '';
          position: absolute;
          width: 8px;
          height: 8px;
          background: #77869e;
          left: -5px;
          top: -4px;
          border-radius: 50%;
        }
        &:nth-last-of-type(1) {
          &:after {
            content: '';
            position: absolute;
            width: 8px;
            height: 8px;
            background: #77869e;
            right: -5px;
            top: -4px;
            border-radius: 50%;
          }
        }
        .desc {
          position: absolute;
          p {
            line-height: 1;
            text-align: left;
            margin-bottom: 0.06rem;
            &.desc-title {
              font-size: 0.14rem;
              font-weight: 400;
              color: #d8e1f3;
              font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
              text-shadow: 0px 0px 15px rgba(27, 126, 242, 0.91);
              .desc-status {
                margin-left: 0.1rem;
              }
            }
            &.desc-time {
              font-size: 0.12rem;
              font-weight: 400;
              color: #d8e1f3;
            }
          }
          &.item-top {
            top: -50px;
            left: 0;
          }
          &.item-bottom {
            top: 50px;
            left: 0;
          }
        }
        .status-text {
          display: flex;
          align-items: center;
          height: 100%;
          justify-content: center;
          font-size: 12px;
        }
        .start-bar {
          width: 94%;
          height: 0.24rem;
          background: linear-gradient(
            -90deg,
            #a8fcff 0%,
            rgba(75, 137, 243, 0) 100%
          );
          border-radius: 4px;
          margin: 3px auto;
        }
        .progess-bar {
          width: 100%;
          height: 100%;
          background: rgba(255, 239, 96, 0.2);
          position: absolute;
          top: 0;
          left: 0;
          &::after {
            position: absolute;
            content: '';
            bottom: 0;
            left: 0;
            width: 100%;
            height: 2px;
            background: #ffef60;
            box-shadow: 0px 0px 4px 2px rgba(255, 239, 96, 0.26);
          }
        }
      }
    }
  }
}
</style>
